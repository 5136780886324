import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "@tightrope/typ"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"


export default function ThankYou() {
  return(
    <>
    <Helmet>
      <style type="text/css">{`
        .typ-module--typContainer h1.typ-module--typHL {
          margin: 30px auto;
          width: 100%;
        }
        `}
        </style>
        <title>Thank You for Downloading Freshy Search</title>
      </Helmet>
    <section>
      <ThankYouPage data={Data} siteData={SiteData}></ThankYouPage>
    </section>
    </>
  )
}
